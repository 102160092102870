.kmz-viewer {
    position: relative;
}

.kmz-viewer-map {
    width: 100%;
    height: calc(100vh - 60px);
}

.kmz-viewer-select-source-map {
    position: absolute;
    top: 10px;
    left: 50px;
}
